export default {
  name: 'book',
  getpicture: {
    url: '/rc-api/common/picture'
  },
  getChapterTree: {
    url: '/rc-api/book/getChapterTree'
  },
  getChapterContent: {
    url: '/rc-api/book/getChapterContent'
  }
}
