export default {
  name: 'statistics',
  // 统计概况数据
  getPageData: {
    url: '/rc-pub/api/rc-publication/statistics/res/page'
  },
  // 获取刊群统计概况
  getGroupStatistic: {
    url: '/rc-pub/api/rc-publication/statistics/res/getGroupStatistic'
  },
  // 导出统计概况数据
  exportPageData: {
    url: '/rc-pub/api/rc-publication/statistics/res/exportPage'
  },
  // 导出统计概况数据
  exportGroupStatistic: {
    url: '/rc-pub/api/rc-publication/statistics/res/exportGroupStatistic'
  },
  // 高浏览文章排行
  getHighBrowseArticle: {
    url: '/rc-pub/api/rc-statistic/getHighBrowseArticle'
  },
  // 导出高浏览文章排行
  exportHighBrowseArticle: {
    url: '/rc-pub/api/rc-statistic/exportHighBrowseArticle'
  },
  // 高下载文章排行
  getHighDownloadArticle: {
    url: '/rc-pub/api/rc-statistic/getHighDownloadArticle'
  },
  // 导出高下载文章排行
  exportHighDownloadArticle: {
    url: '/rc-pub/api/rc-statistic/exportHighDownloadArticle'
  },
  // 高访问作者排行
  getHighVisitsAuthors: {
    url: '/rc-pub/api/rc-statistic/getHighVisitsAuthors'
  },
  // 导出高访问作者排行
  exportHighVisitsAuthors: {
    url: '/rc-pub/api/rc-statistic/exportHighVisitsAuthors'
  },
  // 搜索热点词
  getHotWord: {
    url: '/rc-pub/api/rc-statistic/getHotWord'
  },
  // 导出搜索热点词
  exportHotWord: {
    url: '/rc-pub/api/rc-statistic/exportHotWord'
  },
  // 学术热点
  getHighAcademic: {
    url: '/rc-pub/api/rc-statistic/getHighAcademic'
  },
  // 导出学术热点
  exportHighAcademic: {
    url: '/rc-pub/api/rc-statistic/exportHighAcademic'
  }
}
