// 期刊库
export default {
  name: 'periodicalList',
  // 2.1刊期浏览目录
  stages: {
    url: '/rc-api/periodicals/stages'
  },
  // 获取年
  getYear: {
    url: '/rc-api/periodicals/getYear'
  },
  // 获取期
  getStage: {
    url: '/rc-api/periodicals/getStage'
  },
  // 获取栏目
  getColumn: {
    url: '/rc-api/periodicals/getColumn'
  },
  // 2.2刊期浏览列表
  articles: {
    url: `/rc-api/periodicals/articles`
  },
  // 2.3栏目浏览栏目目录
  columns: {
    url: '/resource/getallissuecloumn.do'
  },
  columnsRC: {
    url: '/rc-api/periodicals/listCatalogs'
  },
  // 2.4栏目浏览文章列表
  columnArticles: {
    url: `/rc-api/periodicals/columns/articles`
  },
  // 2.5统计图表-年度期刊文献量
  yearStageNumber: {
    url: '/rc-api/periodicals/statistic/totalOfYear'
  },
  // 2.6统计图表-年度基金资助文献量
  yearFundSupportNumber: {
    url: '/rc-api/periodicals/statistic/totalOfFundSupport'
  },
  // 2.7统计图表-期刊近十年文献所属栏目的分布
  near10YearsColumnsDistribution: {
    url: '/rc-api/periodicals/statistic/totalOfColumn'
  },
  // 2.8统计图表-近十年文献的学科分布
  near10YearsScienceDistribution: {
    url: '/rc-api/periodicals/statistic/totalOfSubject'
  },
  // 2.9统计图表-近十年文献的关键词分布
  near10YearsKeyWordsDistribution: {
    url: '/rc-api/periodicals/statistic/totalOfKeyword'
  },
  // 2.10统计图表-近十年高发文作者分布
  near10YearsAuthorsDistribution: {
    url: '/rc-api/periodicals/statistic/totalOfAuthor'
  },
  // 2.11统计图表-近十年高发文机构分布
  near10YearsDistribution: {
    url: '/rc-api/periodicals/statistic/totalOfOrg'
  },
  // 2.13创建刊期
  periodicals: {
    url: '/rc-api/periodicals'
  },
  // 2.14上传资源
  theses: {
    url: '/rc-api/periodicals/theses'
  },
  // 2.15上传资源-刊期列表(同2.1)
  // stages: {
  //   url: '/rc-api/periodicals/stages'
  // },
  // 2.16下载模板
  template: {
    url: '/rc-api/periodicals/template'
  },
  // 2.17批量导入论文元数据
  import: {
    url: '/rc-api/periodicals/import'
  },
  // 发布
  totalOfImport: {
    url: `/rc-api/index/totalOfImport`
  },
  // 2.18下载格式列表
  format: {
    url: '/rc-api/periodicals/download/format'
  },
  // 2.19下载
  download(stageId) {
    return `/rc-api/periodicals/${stageId}/download`
  },
  /* attachList (docLibId,docId ) {
    return `/rc-api/common/getAttachList/${docLibId}/${docId}`
  },*/
  // 整期发布
  release(docId) {
    return `/rc-api/periodicals/${docId}/release`
  },
  // 退回生产中心
  backToProductionCenter(docId) {
    return `/rc-api/periodicals/${docId}/backToProductionCenter`
  },
  // 退回生产中心
  lists(docLibId) {
    return `/rc-api/index/${docLibId}/lists`
  },
  //新建期刊是否存在
  isExist: {
    url: '/rc-api/periodicals/isExist'
  },
  // 存储空间统计
  storageStatistics: {
    url: '/rc-api/index/storageStatistics'
  },
  // 论文导出excel
  exportArticleExcel(docIds, isPublished) {
    return `/rc-api/periodicals/exportArticleExcel?docIds=${docIds}&isPublished=${isPublished}`
  },
  // 栏目下论文导出excel
  exportColumnArticleExcel: {
    url: `/rc-api/periodicals/exportColumnArticleExcel`
  }
}
