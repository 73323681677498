import APIS from '../../apis'
import { apps } from '../../configs/register-micro-apps.js'
import FetchLimit from '../../utils/fetchLimit'
import { Asdecrypt } from 'kjqk-commons/src/common/utils'
import Vue from 'vue'
import md5 from 'js-md5'
/* eslint-disable */
const storeName = '_magazine_store_'

export default {
  namespaced: true,
  state: {
    magazines: [],
    centers: {},
    centersMap: null, // 菜单权限的原始数据
    centersFetchingLimit: new FetchLimit(),

    magazineId: '', // 当前杂志ID
    centerPath: null, // 当前栏目
    magazineInfo: null,
    rcapi: null,
    publishPlatformOpen: null,
    productVersion: 0,
    authorCenterUrl: ''
  },
  getters: {
    MAGAZINES: (state) => state.magazines,
    CENTERS: (state) => state.centers,
    RCAPI: (state) => state.rcapi,
    PUBLISH_PLATFORM: (state) => state.publishPlatformOpen,
    MAGAZINE_ID: (state) => {
      // 从 localStorage 中取值
      return getDataFromStorage(state, 'magazineId')
    },
    // 从 localStorage 中取值
    CENTER_PATH: (state) => {
      // 如果 state 中有值就直接返回
      return getDataFromStorage(state, 'centerPath')
    },
    MAGAZINE_INFO: (state) => {
      return getDataFromStorage(state, 'magazineInfo')
    },
    PRODUCT_VERSION: (state) => {
      return getDataFromStorage(state, 'productVersion')
    },
    AUTHOR_CENTER_URL: (state) => {
      return getDataFromStorage(state, 'authorCenterUrl')
    },
    // 当前刊物是否为刊群（2为编审实体刊群，1为非编审实体刊群）
    IS_GROUP: (state, getter) => {
      return getter.MAGAZINE_INFO?.type && getter.MAGAZINE_INFO.type !== '0'
    },
    //刊群时 刊群下所有单刊集合
    JOURNALS: (state, getter) => {
      const journals = []
      getter.MAGAZINES.forEach((item) => {
        if (getter.IS_GROUP && item.groupId === getter.MAGAZINE_ID) {
          journals.push(item)
        }
      })
      return journals
    }
  },
  mutations: {
    RESET_CURRENT(state) {
      state.magazineId = null
      state.centerPath = null
      localStorage.removeItem(storeName + 'magazineId')
      localStorage.removeItem(storeName + 'centerPath')
    },
    UPDATE_STATE: (state, payload) => {
      state[payload.name] = payload.data
      localStorage.setItem(
        storeName + payload.name,
        JSON.stringify(payload.data)
      )
    },
    UPDATE_CENTERS: (state, payload) => {
      // 授权的平台列表
      const centers = Object.keys(payload).filter((centerName) => {
        // 资源中心基础版rcapi 1高级版 2基础版
        if (centerName === 'rcapi') {
          if (payload[centerName] === '1' || payload[centerName] === '2') {
            return true
          }
        } else {
          return payload[centerName] === '1'
        }
      })
      // FIXME 如果当前模块没有权限，但是又想调试，可以在下面手动push一个centerKey
      // centers.push('influenceMonitorCenter')

      // 过滤出来
      state.centers = apps.filter((app) => centers.includes(app.centerKey))
      state.rcapi = payload.rcapi
      state.publishPlatformOpen = payload.publishPlatformOpen
    }
  },
  actions: {
    async GET_MAGAZINES({ commit }, params) {
      const {
        data: { data },
        data: { bsurl }
      } = await APIS.portal.getUserMagazine({
        params: params
      })
      commit('UPDATE_STATE', {
        name: 'magazines',
        data: handleRedundantMagazines(data)
      })
      commit('UPDATE_STATE', {
        name: 'authorCenterUrl',
        data: bsurl
      })
    },
    async GET_MAGAZINE_INFO_WATCH({ state }) {
      let info = state['magazineInfo']
      if (!info?.magazineId) {
        await new Promise((resolve) => {
          const unWatch = this.watch(
            function (state) {
              return state.micro.magazine.magazineInfo
            },
            (val) => {
              if (val) {
                info = val
                unWatch()
                resolve()
              }
            }
          )
        })
      }
      return info
    },
    async GET_CENTER_AUTH({ commit, state }, params) {
      if (state.centersMap) return state.centersMap
      // 已有值时直接访问
      else {
        let magazineid = state.magazineId
        if (!params?.magazineid && !magazineid) {
          // 没有magazineId时监听获取
          magazineid = await new Promise((resolve) => {
            const vm = new Vue()
            const unwatch = vm.$watch(
              () => state.magazineId,
              (val) => {
                if (val) {
                  resolve(val)
                  unwatch()
                }
              },
              { immediate: true }
            )
          })
        }

        const res = await state.centersFetchingLimit.fetch(() =>
          APIS.portal.getMagazineCenterNew({
            params: {
              magazineid,
              ...params
            }
          })
        )
        const data = res.data.data
        const id = md5(data)
        let value = JSON.parse(Asdecrypt(data, 'FounderIt@123_0!'))
        const userInfo = sessionStorage.getItem('userInfo')
          ? JSON.parse(sessionStorage.getItem('userInfo'))
          : null
        if (userInfo.id === value.portalUserId && id === res.headers.secondencry) {
          commit('UPDATE_CENTERS', value)
          state.centersMap = value
          return value
        } else {
          value = {
            appCenter: '0',
            editingApp: '0',
            editorBigData: '0',
            management: '0',
            precisionPush: '0',
            productCenter: '0',
            publishPlatformOpen: '0',
            rcapi: '0',
            workBench: '0'
          }
          commit('UPDATE_CENTERS', value)
          state.centersMap = value
          return value
        }
      }
    },
    // 左侧菜单刊物管理权限（运营平台）
    async GET_MENU_PERMISSIONS(context, magazineId) {
      const {
        data: { data }
      } = await APIS.portal.menuPermissions({
        params: {
          magazineId
        }
      })
      return data
    },
    // 获取运营平台返回的刊物分组
    async GET_PRODUCT_GROUP(context, magazineId) {
      const { data } = await APIS.portal.getGroupByMagazineId({
        params: {
          magazineId
        }
      })
      return data
    },
    // 获取运营平台返回的出版中心版本号
    async GET_PRODUCT_VERSION({ commit }, magazineId) {
      const {
        data: { resultObject }
      } = await APIS.portal.getOrgNewVersion({
        params: {
          magazineId
        }
      })
      commit('UPDATE_STATE', {
        name: 'productVersion',
        data: resultObject || ''
      })
      return resultObject || ''
    },
    // 切刊
    async SET_MAGAZINE({ getters, dispatch, commit }, magazineId) {
      const _magazine = getters.MAGAZINES.find(
        (m) => m.magazineId === magazineId
      )

      sessionStorage.setItem('CURRENT_MAGAZINE_ID', magazineId)
      //  更新 magazineId
      if (_magazine && _magazine.magazineId) {
        commit('UPDATE_STATE', {
          name: 'magazineId',
          data: _magazine.magazineId
        })
        commit('UPDATE_STATE', {
          name: 'magazineInfo',
          data: _magazine
        })
        await dispatch('GET_CENTER_AUTH', { magazineid: _magazine.magazineId })
      }
      sessionStorage.setItem(
        '_magazine                                                                   ',
        JSON.stringify(_magazine)
      )
    }
  }
}

// 返回的数据中有重复的
function handleRedundantMagazines(magazines) {
  const magazineIds = {}
  return magazines.filter((magazine) => {
    if (magazineIds[magazine.magazineId]) {
      return false
    }
    return (magazineIds[magazine.magazineId] = true)
  })
}

function getDataFromStorage(state, key) {
  // 如果 state 中有值就直接返回
  if (state[key]) {
    return state[key]
  }
  // 否则从 localStorage 中获取
  if (state.magazines.length) {
    const current = localStorage.getItem(storeName + key)
    if (current && current !== 'undefined') {
      state[key] = JSON.parse(current)
      return state[key]
    }
  }
  return null
}
