export default {
  name: 'forgetPsd',
  getmessagecode: {
    url: '/index/login/getmessagecode.do '
  },
  checkmessagecode: {
    url: '/index/login/checkmessagecode.do'
  },
  findpassword: {
    url: '/index/login/findpassword.do'
  },
  captcha: {
    url: '/index/login/captcha.do'
  }
}
