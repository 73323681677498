import createAPI from '../middleware/createAPI'

export default {
  // 获得用户的信息
  getUserInfo: (config) => createAPI('/portal/userinfo', 'get', config),
  // 获得用户刊物信息 下拉列表
  getUserMagazine: (config) => createAPI('/portal/usermagazine', 'get', config),
  // 获得 刊物开通 模块
  getMagazineCenterNew: (config) =>
    createAPI('/portal/magazinecenterNew', 'get', config),
  // 获得 刊物开通 子模块
  menuPermissions: (config) =>
    createAPI('/magazine/getPortalMagazineMenu', 'get', config),
  // 获取组
  getGroupByMagazineId: (config) =>
    createAPI('/magazine/getGroupByMagazineId', 'get', config),
  getAdvid: (config) => createAPI('/index/login/getAdvid.do', 'get', config),
  // 运营平台接口 获得出版中心版本号
  getOrgNewVersion: (config) =>
    createAPI('/magazine/getOrgNewVersion', 'get', config),
  // 获取用户刊物某一中心下菜单权限
  getUserMagazineCenterMenu: (config) =>
    createAPI(' /portal/usermagazinemenubypid', 'get', config),
  // 获取ai对话的token
  getAITalkToken: (config) =>
    createAPI('/portal/appcenter/getAITalkToken', 'get', config)
}
