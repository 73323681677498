// 网站基本信息设置
export default {
  name: 'basicInformation',
  // 获取网站扩展信息
  getSiteExtInfo: {
    url: '/rc-pub/api/site/getSiteExtInfo'
  },
  // 设置网站扩展信息
  setSiteExtInfo: {
    url: '/rc-pub/api/site/setSiteExtInfo'
  },
  // 根据站点id获取头部配置
  getConfigHeader: {
    url: `/rc-pub/api/site/getConfigHeader`
  },

  // 设置头部配置
  setConfigHeader: {
    url: `/rc-pub/api/site/setConfigHeader`
  },
  // 根据站点id获取尾部部配置
  getFooterConfig: {
    url: `/rc-pub/api/config-footer/getFooterConfig`
  },
  // 编辑底部配置信息
  editFooterConfig: {
    url: `/rc-pub/api/config-footer/editFooterConfig`
  },
  // 网刊配置信息
  getPublicationConfig: {
    url: `/rc-pub/api/config-publication/getPublicationConfig`
  },
  // 编辑网刊配置信息
  editConfig: {
    url: `/rc-pub/api/config-publication/editConfig`
  },
  //设置论文跳转
  setJumpType: {
    url: '/rc-pub/api/site/setJumpType'
  },
  // 检索条件列表
  searchconditions(siteId) {
    return `/rc-pub/api/site/${siteId}/search-conditions`
  },
  // 修改检索条件
  updateconditions(siteId, conditionId) {
    return `/rc-pub/api/site/${siteId}/search-conditions/${conditionId}?act=update`
  },
  //  移动检索条件
  moveeconditions(siteId, conditionId) {
    return `/rc-pub/api/site/${siteId}/search-conditions/${conditionId}?act=move`
  },
  // 新增检索条件
  addcondition(siteId) {
    return `/rc-pub/api/site/${siteId}/search-conditions`
  },
  //删除站点检索条件
  deleteconditon(siteId, conditionId) {
    return `/rc-pub/api/site/${siteId}/search-conditions/${conditionId} `
  },
  //查询站点检索字段
  getsitesearch: {
    url: '/rc-pub/api/dictionaries/site-search-fields'
  },
  // 获取年份列表
  getvolumeyearlist: {
    url: '/rc-pub/api/rc-period/getYearListBySiteId'
  },
  // 编辑年卷期规则
  upatevolume: {
    url: '/rc-pub/api/config/volume/edit'
  },
  // 删除年卷期规则
  deletevolume: {
    url: '/rc-pub/api/config/volume/delete'
  },
  // 获取单个年卷期规则
  getvolumeinfo: {
    url: '/rc-pub/api/config/volume/info'
  },
  // 获取年卷期规则list
  getvolumelist: {
    url: '/rc-pub/api/config/volume/findBySiteId'
  },
  // 获取刊期列表
  getissuelist: {
    url: '/rc-pub/api/rc-period/getIssueListByYearAndSiteId'
  },
  //添加年卷期规则
  addvolume: {
    url: '/rc-pub/api/config/volume/add'
  },
  // 获取聚类导航配置
  getNavigationConfig: {
    url: '/rc-pub/api/site/getClusterNavigation'
  },
  // 编辑聚类导航配置
  setClusterNavigation: {
    url: '/rc-pub/api/site/setClusterNavigation'
  },
  // 修改站点信息接口
  setSiteInfo(siteId) {
    return `/rc-pub/api/site/${siteId}`
  }
}
