export default {
  name: 'sysInfo',
  getList: {
    url: '/magazine/userCenter/systemMessage/list.do'
  },
  delete: {
    url: '/magazine/userCenter/systemMessage/delete.do'
  },
  setMessageRead: {
    url: '/magazine/userCenter/systemMessage/setMessageRead.do'
  },
  getMessageFile: {
    url: '/magazine/userCenter/systemMessage/getMessageFile.do'
  },
  unReadMessage: {
    url: '/magazine/userCenter/systemMessage/unReadMessage.do'
  },
  feiXiangToolDown: {
    url: '/newUI/feiXiangToolDown.do'
  },
  wordToolDown: {
    url: '/newUI/wordToolDown.do'
  },
  loginout: {
    url: '/index/login/loginout.do'
  },
  setMessageType: {
    url: '/magazine/userCenter/systemMessage/setMessageType.do'
  }
}
