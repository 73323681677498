export default {
  name: 'periodManage',
  list: {
    url: '/magazine/magazineIssue/list.do'
  },
  fill: {
    url: '/magazine/magazineIssue/formFill.do'
  },
  batch: {
    url: '/magazine/magazineissue/batchAdd.do'
  },
  save: {
    url: '/article/issueColumnSave.do'
  },
  del: {
    url: '/magazine/magazineIssue/delete.do'
  },
  bookArticleList: {
    url: '/article/bookArticleList.do'
  },
  qiYongCheck: {
    url: '/magazine/qiYongCheck.do'
  },
  getJournalList: {
    url: '/magazinePortal/getMagazineType.do'
  },
  // 获取刊物下指定角色用户列表
  getRoleUserList: {
    url: '/portal/roleUserList'
  }
}
