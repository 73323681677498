import Vue from 'vue'
import Vuex from 'vuex'
import incrementService from './modules/incrementService'
// import producePlantForm from './modules/producePlantForm'

Vue.use(Vuex)

export default {
  modules: {
    incrementService
    // producePlantForm
  }
}
