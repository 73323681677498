export default {
  name: 'bindPhone',
  bindCode: {
    url: '/index/login/bindCode.do'
  },
  bind: {
    url: '/index/login/bind.do'
  },
  getbind: {
    url: '/index/login/getbind.do'
  }
}
