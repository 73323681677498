export default {
  // 内容聚合
  name: 'contentAggregation',
  // 获取优先出版论文
  GetFirstArticle: {
    url: '/das-api/vas/mag/getFirstArticle'
  },
  // 获取整期发表期刊
  GetIssues: {
    url: '/das-api/vas/mag/getIssuesPage'
  },
  // 获取整期发表期刊详情页
  GetIssuesDetails: {
    url: '/das-api/vas/mag/get'
  },
  // 获取高被引文章
  GetArticle: {
    url: '/das-api/vas/statistics/article'
  },
  // 获取封面
  GetPictureStream: {
    url: '/das-api/vas/push/configManage/getPictureStream'
  },
  // 获取详情页列表
  GetIssueArticles: {
    url: '/das-api/vas/mag/getIssueArticles'
  }
}
