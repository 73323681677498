//论文库
export default {
  name: 'paperList',
  // 列表
  thesisList: {
    url: '/rc-api/theses'
  },
  // 高级筛选
  advancedFilters: {
    url: '/rc-api/theses/advanced/filters'
  },
  // 上传附件
  uploadAttachments(thesisId) {
    return `/rc-api/theses/${thesisId}/uploadAttachments`
  },
  // 发布
  release: {
    url: `/rc-api/theses/release`
  },
  // 下载
  download: {
    url: `/rc-api/theses/download`
  },
  backDealPerson: {
    url: '/rc-api/article/getHandler.do'
  }, //论文回退select接口
  backToProductionCenter: {
    url: '/rc-api/theses/backToProductionCenter'
  }, //论文回退接口
  paperPushReceivers(thesisId) {
    return '/rc-api/theses/' + thesisId + '/receivers'
  }, //论文精准推送查询收件人
  paperPushdetail(thesisId) {
    return '/rc-api/theses/' + thesisId + '/detail'
  }, //论文精准推送推送论文信息
  paperPushSubmit(thesisId) {
    return '/rc-api/theses/' + thesisId + '/push'
  }, //论文精准推送提交
  thesesAuthors(thesisId) {
    return '/rc-api/theses/' + thesisId + '/authors'
  }, //相关作者
  thesesDetailContent(thesisId) {
    return '/rc-api/theses/' + thesisId + '/detailContent'
  }, //论文详情内容
  thesesDetails(thesisId) {
    return '/rc-api/theses/' + thesisId + '/theses'
  }, //论文详情内容
  thesesDetailXml(thesisId) {
    return '/rc-api/theses/' + thesisId + '/detailXml'
  },
  //论文版本
  thesesVersion(thesisId) {
    return '/rc-api/theses/' + thesisId + '/version'
  },
  //相关热词接口
  hotWords: {
    url: `/rc-api/theses/hotWords`
  },
  thesesAtlas: {
    url: '/rc-api/theses/atlas'
  },
  downloadFormat: {
    url: '/rc-api/theses/download/format'
  },
  researchTrend: {
    url: '/rc-api/theses/researchTrend'
  },
  sendAuthors(docId) {
    return `/rc-api/theses/${docId}/sendAuthors`
  },
  highFrequency(docLibId, num) {
    return `/rc-api/theses/highFrequency/${docLibId}/${num}`
  },
  relatedAttach(docId) {
    return `/rc-api/theses/${docId}/relatedAttach`
  },
  batchDownloadXml: {
    url: '/rc-api/theses/batchDownloadXml'
  },
  getThesisRelateInfo: {
    url: '/rc-api/theses/getThesisRelateInfo'
  },
  getThesisRelateInfoByIds: {
    url: '/rc-api/theses/getThesisRelateInfoByIds'
  },
  // 优先论文批量导入
  thesesImport: {
    url: '/rc-api/theses/import'
  }
}
