import createAPI from '../middleware/createAPI'
const _createAPI = function (url, ...config) {
  return createAPI('/je-api/journal-edit-article' + url, ...config)
}
export default {
  // 期刊下未读的留言个数
  getArticleMessageUnreadCount: (config) =>
    _createAPI('/v1/article/message/unread/count/all', 'get', config),
  // 期刊下留言列表
  postArticleMessageRecords: (config) =>
    _createAPI('/v1/article/message/records/all', 'post', config),
  // 消息全部已读
  postArticleMessageRecordsRead: (config) =>
    _createAPI('/v1/article/message/records/all/read', 'post', config)
}
