/**
 * 0:正常
 * 1——1000：微服务框架预留错误码
 * 1000——2000：E5平台错误码，为了支持国际化，不直接返回错误信息，而是使用错误码。
 * 业务系统应该从10000开始定义错误码
 * 1001：实际数据已经不存在，如一篇稿件已经被删除
 * 1002：实际数据的状态已经发生改变，如一篇稿件已经被别人提交审批了
 * 1003：数据已经被别人锁定，别人正在处理中。
 **/

import axios from 'axios'

const _axios = axios.create({
  baseURL: '',
  timeout: 200000 // request timeout
})
const yyptApiCludes = [
  'magazine/checkparam',
  'magazine/findMagazineById',
  'magazine/update',
  'magazine/gettemplatelist',
  'magazine/saveorupdaterevisionsetting',
  'magazine/getrevisionsettinginfo',
  'magazine/getGroupByMagazineId',
  'operate/notice',
  // 管理中心
  'magazine/getPortalMagazineMenu',
  '/magazine/getOrgNewVersion',
  'org/portalUser',
  '/org/',
  '/magazineMessage/',
  '/multi/',
  '/rc-manager/'
]
const userInfo = sessionStorage.getItem('userInfo')
  ? JSON.parse(sessionStorage.getItem('userInfo'))
  : null

// 添加token
_axios.interceptors.request.use(
  (config) => {
    // FIXME 临时token 能够放行 ids
    if (process.env.NODE_ENV !== 'production') {
      let token =
        // zxh 测试环境
        'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJwX0paS1dxSnZCbndvZ3ZLUzl2ZWZkcUhHcHRCWkZONWMtb255SFR2Tk4wIn0.eyJleHAiOjE3NDIyMTI3MTIsImlhdCI6MTc0MjIwNTUxMiwiYXV0aF90aW1lIjoxNzQyMTc4MjQ0LCJqdGkiOiI4YjBhZDQzYS05ZDZmLTRiZDUtOGI5MS04YjBlZmRkZmVhNzYiLCJpc3MiOiJodHRwczovL2pvdXJuYWwuaWRzLmZ6eXVuLmNuL2F1dGgvcmVhbG1zL2pvdXJuYWwiLCJhdWQiOiJqb3VybmFsLXBvcnRhbCIsInN1YiI6IjMwYTdlOTE2LWI4NTMtNDg5Yi1hYWRhLThkMzBkMzRjZmVmYiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImpvdXJuYWwtcG9ydGFsIiwic2Vzc2lvbl9zdGF0ZSI6ImU0MDZkYmMyLTRkYzEtNDNkYi1hOTRkLTE5YWZmNzcwMjUyZiIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2pvdXJuYWwucG9ydGFsLmZvdW5kZXJzcy5jbiIsIioiLCJodHRwOi8vam91cm5hbC5wb3J0YWwuZm91bmRlcnNzLmNuIl0sInNjb3BlIjoib3BlbmlkIHBob25lIHByb2ZpbGUgZW1haWwiLCJzaWQiOiJlNDA2ZGJjMi00ZGMxLTQzZGItYTk0ZC0xOWFmZjc3MDI1MmYiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiLnoJTlj5Et6LW15rW35qyiIiwicGhvbmVfbnVtYmVyIjoiMTgzMDEzMDg3MDAiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ6aGFvMTIzIiwibG9jYWxlIjoiemgtQ04iLCJmYW1pbHlfbmFtZSI6IueglOWPkS3otbXmtbfmrKIiLCJlbWFpbCI6InpoYW9oaEBmb3VuZGVyLmNvbSJ9.QomfXQGqHn4BYBycjvdyY8MtcPK2wxaKTngng4fzR0rV1mOUaLqhyClkew6z5MyvDJE4molQKqgCOsTYvLCsqfAw0tgfw6v8xBdFAj_D6YgxT8JwgjoKIjfgq7HKEB8GYgwNAcGC96pnOGEuFR5e4fc-VB2zhn16adoduffXdQr1_u_TSgNavYQSqhAuZ65KniTvz6guVUMRfglQ2zOtiFqCyGYrkhMbbLHxJWQVQi-TTolqTECRfslJUc0snKkDdImiuZlIE25vLcxfQKTWc3Xkl1TEiSiw0Z1hdkws7JYIYyUWoAH1OcdtdylrLGugyRuoc1ah-COhKZbE8hd-ZA'
      // zhao123 开发环境
      // 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI4SUpLOWJzM0pRNUctcU5jd18wZkhJUHpWTWFqVTNYLXlTTkFfdGs5Y2ZBIn0.eyJleHAiOjE3Mjc4NjM5MTgsImlhdCI6MTcyNTM1ODMxOCwianRpIjoiYmJjZGNiNTktN2JiZS00MWVjLTkwZjYtYmMwNGU3NmE4MDlkIiwiaXNzIjoiaHR0cHM6Ly9xaWthbi5pZHMudGVzdHguZnp5dW4uaW8vYXV0aC9yZWFsbXMvcWlrYW4iLCJhdWQiOiJxaWthbi1wb3J0YWwiLCJzdWIiOiIzNTIyY2U2OC05ZTNlLTQyZmQtYTg0ZC01OTBhOWEyN2U3MWMiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJxaWthbi1wb3J0YWwiLCJzZXNzaW9uX3N0YXRlIjoiZjhkZWVmNWUtMWFkMy00MzQ1LTliNjAtZTVhODZhN2U3MzdlIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHA6Ly9tYWdhemluZS1pZHMuZGV2Ni5menl1bi5pbyJdLCJyZXNvdXJjZV9hY2Nlc3MiOnsicWlrYW4tcG9ydGFsIjp7InJvbGVzIjpbInZpc2l0b3IiXX19LCJzY29wZSI6ImNhc1VzZXJJZCBwcm9maWxlIGVtYWlsIiwic2lkIjoiZjhkZWVmNWUtMWFkMy00MzQ1LTliNjAtZTVhODZhN2U3MzdlIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJuYW1lIjoi6LW15rW35qyiIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiemhhbzEyMyIsImZhbWlseV9uYW1lIjoi6LW15rW35qyiIiwiZW1haWwiOiJ6aGFvaGhAZm91bmZlci5jb20uY24ifQ.dcpG5UfgYduRwVCyJKwMUqCIW0pSKcfM-fCg1pQC1QNqgMXD8i2eBNyo7qQE1Uehs9G3YnJh9qltoVc3FqWUQ7c8aZODrT2V2yNWBNBdkH0qpl8ic6NrVfhcQMTLp2mrc8FPsfKuLp2dUjhrKV_PfhThbedXF8FxRnapCx_jy9qIS0Ir7MeDjVhNp9eQR7N3ibouud011HCd9T7C5VCzM9xa-fB9d-o4xKRUGnzeco62ACz5qPZpg9KTmoFAcXbtFJSUmsXbIFaiRTHwriOVa-ahp1k92Vki5aAxiq23O2Cu88XKEYv-i71EpH1cKHI88pD_8LFwt83SPw6fAlwMLQ'
      // guolinjing1 测试环境
      //'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpVHlTZ3pQUC1idEY5YkRzT3VNdXU2MXpidktxcWg5NGxMNkMyUnBTeVBZIn0.eyJleHAiOjE2NjQ0MTgyMjcsImlhdCI6MTY2NDQxNjQyNywianRpIjoiOGVkNTgzYjgtM2YxNC00MzZlLThmYWEtOTM0ZmRhMTMwNWY2IiwiaXNzIjoiaHR0cHM6Ly9qb3VybmFsLmlkcy5zdGFnZXguZnp5dW4uY24vYXV0aC9yZWFsbXMvam91cm5hbCIsImF1ZCI6ImpvdXJuYWwtY29udHJpYnV0aW5nIiwic3ViIjoiNzBiOGI3YzQtYTM5MS00YmMxLTgzYjItNTA2NTc0NGE3YmNlIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiam91cm5hbC1jb250cmlidXRpbmciLCJzZXNzaW9uX3N0YXRlIjoiMDYxNmY3MzktMjAyYi00YjA2LWI4NTctZTZlN2E2YWQyZTA3IiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vY29udHJpYnV0aW5nLnRlc3QucG9ydGFsLXRlc3QuZm91bmRlcnNzLmNuIl0sInJlc291cmNlX2FjY2VzcyI6eyJqb3VybmFsLWNvbnRyaWJ1dGluZyI6eyJyb2xlcyI6WyJ2aXNpdG9yIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIGNhc1VzZXJJZCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6Imd1b2xpbmppbmcxIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiZ3VvbGluamluZzEiLCJmYW1pbHlfbmFtZSI6Imd1b2xpbmppbmcxIiwiZW1haWwiOiIyMjIwQHFxLmNvbSJ9.QEVN2ZiX-lyCd0k39RgOepRZxR4qi2l5dMo-EOe7D_SDEdzfQox6wJtqlcfDOScpmdcwHXVm2R5tYBwUuKxOFcYOUWg-dd0-dE-YJXHZTzI8w9vWCrjYA4CFoliac0aidsVCAtCOwVETXQpBR69eC9t8hop6WbCtmY3KKsK2fUb_jqLnJtVTr7XLo-D8Nkabmbo0fDwLCDGf_05p74z80KKvf53NpQilgAaP6zgWAXoJF7wQBwmapucH6VJHIHMdNF8bGDmQqCTwsd1N7oy6ptOealHXnMcdT4qy8IiQwn5HG1qAdy9stSqj5k9aFW0HfYvUoxAYJpVuxBPypRNySQ'
      // guolinjing2 测试环境
      // 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJpVHlTZ3pQUC1idEY5YkRzT3VNdXU2MXpidktxcWg5NGxMNkMyUnBTeVBZIn0.eyJleHAiOjE2NjIwMTgyODksImlhdCI6MTY2MjAxNjQ4OSwianRpIjoiYTllNjdmN2QtOGU3ZC00ZWNjLTliZGUtMGY4YmNmZmU1YWU1IiwiaXNzIjoiaHR0cHM6Ly9qb3VybmFsLmlkcy5zdGFnZXguZnp5dW4uY24vYXV0aC9yZWFsbXMvam91cm5hbCIsImF1ZCI6ImpvdXJuYWwtY29udHJpYnV0aW5nIiwic3ViIjoiMDJhMjExMjQtOGQ0My00OTc5LTkzZmItZDIzOWM2M2M2ZWRmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiam91cm5hbC1jb250cmlidXRpbmciLCJzZXNzaW9uX3N0YXRlIjoiZjYyM2UzNmMtNWE5ZC00MzBhLWJlNGMtY2ViODc3ZGMyNWZiIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwOi8vY29udHJpYnV0aW5nLnRlc3QucG9ydGFsLXRlc3QuZm91bmRlcnNzLmNuIl0sInJlc291cmNlX2FjY2VzcyI6eyJqb3VybmFsLWNvbnRyaWJ1dGluZyI6eyJyb2xlcyI6WyJ2aXNpdG9yIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIGNhc1VzZXJJZCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6Imd1b2xpbmppbmcyIiwicHJlZmVycmVkX3VzZXJuYW1lIjoiZ3VvbGluamluZzIiLCJmYW1pbHlfbmFtZSI6Imd1b2xpbmppbmcyIiwiZW1haWwiOiJndW9saW5qaW5nMkBpdWdlLmNvbSJ9.YmLE8dktxET7iaRg4wYZtic4KfcyuSvbQweK4tImzo61zK0U5nzP4EPAemVabHBwCPuZBGsPd_UHsiU04v6nwL-_LCEfRC6T-usmgWmEuwxb2M0eVnQAc3iiZkLhhxKxFWAHyJGHlRO6i773BkRACfgsIkwE64Qd4EJ7a2GJPfD_d0qQgvL2-otNkfyCDClmUFiG05rBfCSkQIXt-_4ynlSgRVz8UwAHtm0kUhmidjT0w1q95Zig2SzlQLVfcbW51YmUMdWKjjqaeA0pWj0WzMDLrjsIEbnEDfzcogNdFPY9CFspb7Z_NCDcSU29VhZlrf91POMsm4Vcj8CM902puw'
      // 编审中心用户权限
      let JournalAuth =
        // 宫丽杰 开发环境
        'eyJhY2NvdW50bmFtZSI6InpoYW8xMjMiLCJlbWFpbCI6InpoYW9oaEBmb3VuZGVyLmNvbSIsImlzQWRtaW4iOmZhbHNlLCJqb3VybmFsVHlwZSI6MCwibWFnYXppbmVjb2RlIjoiV0hDSCIsIm1hZ2F6aW5laWQiOiIyNDFlZTE2ZjVhYzk0YmZlOWEwMWIwMDE3YmZlY2IwZCIsIm1hZ2F6aW5lbmFtZSI6IuatpuaxieWkp+WtpuWtpuaKpcK35L+h5oGv56eR5a2m54mIIiwib3JnQ29kZSI6IndocWsiLCJ0ZWxlcGhvbmUiOiIxODMwMTMwODcwMCIsInVzZXJpZCI6IjQ4YWY4MzhiLTZmNzMtNDIzOS1hOGFlLTA1NWM1MDdlYTQ3OCIsInVzZXJuYW1lIjoi56CU5Y+RLei1tea1t+asoiJ9'
      config.headers.Authorization = `Bearer ${token}`
      config.headers.JournalAuth = JournalAuth
    }
    if (config.url.indexOf('/magazine/') !== -1) {
      config.headers['orgCode'] = sessionStorage.getItem('orgCode')
    }
    yyptApiCludes.forEach((item) => {
      if (config.url.indexOf(item) !== -1) {
        config.headers.portal = '77aaf908d3994a95bf4a8e3ca31851ea'
        config.headers.orgCode = 'magazinemanager'
      }
    })
    config.headers.rcpub = sessionStorage.getItem('orgCode')
    config.headers.operatorLoginName = userInfo && userInfo.loginName
    return config
  },
  (error) => {
    let response = error.response
    if (
      response.status === 401 ||
      response.status === '401' ||
      (response.data && response.data.status === 401)
    ) {
      // Store.commit('auth/LOGOUT')
    }
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use((response) => {
  const status = response.data.status
  if (window.location.pathname.indexOf('/updating') === -1) {
    if (status == '666') {
      window.location.href =
        '/updating?r=' +
        window.location.pathname +
        window.location.search +
        `&t=${response.data?.data?.time || ''}`
    }
  }
  return response
})

export default _axios
