// 机构库
export default {
  name: 'mechanismLibrary',
  organizationLibs: {
    url: '/rc-api/organizationLibs'
  }, //查询机构列表
  editMechanism(docId) {
    return `/rc-api/organizationLibs/${docId}`
  },
  // 机构主要作者
  authors: {
    url: '/rc-api/organizationLibs/statisticalChart/authors'
  },
  // 机构库查重
  repetition: {
    url: '/rc-api/organizationLibs/repetition'
  },
  // 基金统计
  funds: {
    url: '/rc-api/organizationLibs/statisticalChart/funds'
  },
  // 关键词统计
  keyWords: {
    url: '/rc-api/organizationLibs/statisticalChart/keyWords'
  },
  import: {
    url: '/rc-api/organizationLibs/import'
  },
  // 刊群导入
  importGroup: {
    url: '/rc-api/organizationLibs/importGroupData'
  },
  // 新建机构
  addOrganization: {
    url: '/rc-api/organizationLibs/addOrganization'
  },
  merge(docLibId) {
    return `/rc-api/common/${docLibId}/repetition/merge`
  }
}
