//资讯管理
export default {
  name: 'memberBehavior',
  // 会员行为
  getHistory: {
    url: '/rc-pub/api/members/historys'
  },
  getDownload: {
    url: '/rc-pub/api/members/downloads'
  }
}
