export default {
  name: 'templateManage',
  // 列表页
  getTemplateList: {
    url: '/rc-api/VAS/push/templateManage/getTemplateList.do'
  },
  // 新建
  add: {
    url: '/rc-api/VAS/push/templateManage/add.do'
  },
  // 编辑
  update: {
    url: '/rc-api/VAS/push/templateManage/update.do'
  },
  // 删除
  delete: {
    url: '/rc-api/VAS/push/templateManage/delete.do'
  },
  // 获取模板样式
  getTemplateStyles: {
    url: '/rc-api/VAS/push/templateManage/getTemplateStyles.do'
  },
  // banner 上传
  uploadBanner: {
    url: '/rc-api/VAS/push/templateManage/uploadBanner.do'
  }
}
