export default {
  Am: 'manuscriptCenter',
  Am0: 'manuscriptList',
  Am1: 'firstList',
  Am2: 'groupJournalList',
  Am3: 'withDrawList',
  An: 'groupjournalCenter',
  Ad: 'incrementService',
  Aq: 'managementCenter',
  Aq0: 'manuscriptManage',
  Aq4: 'groupInfo',
  Aq3: 'userManage',
  Au: 'userCenter',
  Au1: 'userInfo',
  Au2: 'sysInfo',
  Ar: 'resourceCenter',
  Ac: 'publishCenter'
}
