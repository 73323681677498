export default {
  name: 'audioLibrary',
  // 查询音频列表
  audioList: {
    url: '/rc-api/voiceLibs'
  },
  // 下载音频附件
  download(voiceId) {
    return '/rc-api/voiceLibs/' + voiceId + '/download'
  },
  // 上传音频
  upload: {
    url: '/rc-api/voiceLibs'
  },
  // 编辑音频
  edit(voiceId) {
    return `/rc-api/voiceLibs/${voiceId}`
  },
  // 音频标引关键词
  index: {
    url: '/rc-api/voiceLibs/index'
  },
  // 删除音频
  deleteAudio: {
    url: '/rc-api/voiceLibs'
  },
  // 音频播放
  // 文章相关音频
  thesesVoices: {
    url: '/rc-api/voiceLibs/theses/voices'
  },
  // 音频相关音频
  relevantAudios: {
    url: '/rc-api/voiceLibs/voices'
  },
  // 音频相关视频
  relevantVideos: {
    url: '/rc-api/voiceLibs/videos'
  }
}
