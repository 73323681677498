export default {
  name: 'widthDrawList',
  getList: {
    url: '/article/rescindList.do'
  },
  getStatus: {
    url: '/article/sectionState.do'
  },
  recover: {
    url: '/article/reductionArticle.do'
  },
  delete: {
    url: '/article/deleteArticle.do'
  }
}
