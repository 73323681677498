export default {
  name: 'groupJournalList',
  getList: {
    url: '/article/groupList.do'
  },
  getStatus: {
    url: '/article/sectionState.do'
  },
  // 退回修改
  backEdit: {
    url: '/article/backEdit.do'
  },
  //撤回
  return: {
    url: '/article/undoArticle.do'
  },
  magazineDownload: {
    url: '/common/magazineDownload.do'
  },
  priorDownload: {
    url: '/article/priorDownload.do'
  },
  manage: {
    url: '/article/versionManage.do'
  },
  htmlDownload: {
    url: '/article/htmlDownload.do'
  },
  fileDownload: {
    url: '/common/download.do?'
  },
  htmlShow: {
    url: '/article/htmlShow.do'
  },
  magazineVersionXmlDownload: {
    url: '/common/magazineVersionXmlDownload.do'
  },
  magazinePdfDownload: {
    url: '/article/exportreport.do'
  },
  articleReMulti: {
    url: '/article/articleReMulti.do'
  },
  comparisonversion: {
    url: '/comparisonversion/comparisonversion.do'
  },
  wechat: {
    url: '/article/wechat.do'
  },
  tuningCheck: {
    url: '/article/tuningCheck.do'
  },
  getPublisher: {
    url: '/article/getPublisher.do'
  },
  journalYear: {
    url: '/magazine/journalYearList.do'
  },
  journalYearIssueColList: {
    url: '/magazine/journalColList.do'
  },
  groupPublication: {
    url: '/article/groupPublication.do'
  },
  journalYearIssueList: {
    url: '/magazine/journalYearIssueList.do'
  },
  transfer: {
    url: '/article/transfer.do'
  },
  getHandler: {
    url: '/article/getHandler.do'
  },
  tuningSubmit: {
    url: '/article/tuningSubmit.do'
  },
  refreshArticle: {
    url: '/article/refreshArticle.do'
  },
  blindTrialDownload: {
    url: '/common/blindTrialDownload.do'
  },
  jiaoCiPublisher: {
    url: '/article/jiaoCiPublisher.do'
  },
  jiaoCiSubmit: {
    url: '/article/jiaoCiSubmit.do'
  },
  jiaoCiList: {
    url: '/article/jiaoCiList.do'
  },
  cancelNetpress: {
    url: '/article/fistCancel.do'
  },
  getZipMulti: {
    url: '/article/getZipMulti.do'
  },
  zipMultiDown: {
    url: '/article/zipMultiDown.do'
  },
  jiaoCiFileDelList: {
    url: '/article/jiaoCiFileDelList.do'
  },
  jiaoCiFileList: {
    url: '/article/jiaoCiFileList.do'
  },
  jiaoCiFileZipList: {
    url: '/article/jiaoCiFileZipList.do'
  },
  jiaoCiFileSaveList: {
    url: '/article/jiaoCiFileSaveList.do'
  },
  onlineEditVersionStart: {
    url: '/article/onlineEditVersionStart.do'
  }
}
