// 关键词库
export default {
  name: 'keyWords',
  list: {
    url: '/rc-api/keyWordLibs'
  },
  // 关键词修改
  keyWordsModify(docId) {
    return `/rc-api/keyWordLibs/${docId}`
  },
  // 导出关键词
  exportKeyWord: {
    url: '/rc-api/common/books/export'
  },
  // 相关图片
  pictures: {
    url: '/rc-api/keyWordLibs/pictures'
  },
  // 新建关键词
  addKeyWords: {
    url: '/rc-api/keyWordLibs/addKeyWords'
  },
  // 关键词查重
  repetition: {
    url: '/rc-api/keyWordLibs/repetition'
  },
  // 合并
  merge(docLibId) {
    return `/rc-api/common/${docLibId}/repetition/merge`
  },
  keyWordsImport: {
    url: '/rc-api/keyWordLibs/import'
  },
  // 刊群导入
  keyWordsImportGroup: {
    url: '/rc-api/keyWordLibs/importGroupData'
  }
}
