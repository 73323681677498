/**
 * Created by codingnuts on 2018/6/21.
 */
import CryptoJS from 'crypto-js'

const secretKey = 'kjqk'

export function getItem(key) {
  if (sessionStorage.getItem(key)) {
    return CryptoJS.AES.decrypt(
      sessionStorage.getItem(key),
      secretKey
    ).toString(CryptoJS.enc.Utf8)
  }
}

export function setItem(key, value) {
  sessionStorage.setItem(key, CryptoJS.AES.encrypt(value, secretKey).toString())
}
