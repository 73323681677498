module.exports = transferArrayToObject([
  /*************************** increment-service **************************** */
  {
    context: ['/das-api/'],
    // target: 'http://www.das-dev.publish-test.founderss.cn', // 增值服务测试环境
    target: 'http://www.das.publish.founderss.cn', //正式环境
    changeOrigin: true,
    secure: false
  },
  /*************************** publish-center **************************** */
  {
    context: ['/rc-pub/'],
    target: 'http://gateway-web.publish.testx.fzyun.io',
    changeOrigin: true,
    secure: false
  },
  /*************************** 影响力监控 **************************** */
  {
    context: ['/statistics/pub/'],
    target: 'http://pub.publish.testx.fzyun.io',
    changeOrigin: true,
    secure: false,
    pathRewrite: {
      '^/statistics/pub': ''
    }
  },
  {
    context: ['/statistics/edit/'],
    target: 'https://journal.testx.fzyun.io/',
    // target: 'http://journal.devx.fzyun.io/',
    changeOrigin: true,
    secure: false
  },
  // "{"customButtonList":[{"customButtonName":"中文核心期刊1","enCustomButtonName":"返回旧版test","customButtonLinkValue":"https://www.baidu.com/","customButtonStatus":1,"id":"cbaad57e-eec1-4173-82d9-428fa1f67d8e"},{"customButtonName":"CSCD, ScopuS, EBSCO,JST收录CCF推荐中文期刊","enCustomButtonName":"CSCD, ScopuS, EBSCO,JST收录CCF推荐中文期刊","customButtonLinkValue":"https://journal.testx.fzyun.io/service/publish/globalConfiguration/navbar?columnId=2&siteId=761&columnName=%E7%BD%91%E7%AB%99%E5%85%A8%E5%B1%80%E9%85%8D%E7%BD%AE","customButtonStatus":0,"id":"9ac8a662-5bda-4e70-8053-8b4b5075cbff"},{"customButtonName":"图像图形领域高质量科技期刊分级T1级","enCustomButtonName":"图像图形领域高质量科技期刊分级T1级","customButtonLinkValue":"https://journal.testx.fzyun.io/service/publish/globalConfiguration/navbar?columnId=2&siteId=761&columnName=%E7%BD%91%E7%AB%99%E5%85%A8%E5%B1%80%E9%85%8D%E7%BD%AE","customButtonStatus":0,"id":"31a0599c-1db5-45cf-aa36-07a7f82b2981"}],"cssContent":".content-people li .img{height:237px !important;}\n.content-people li .img .img-div{background-size: cover !important;}\n.layout-8-1>div:nth-child(1){display: flex;justify-content: space-between;}\n.titleBoxJQ{background:url('/api/2116727')  no-repeat  center top;}\n.full-column-style_1{background:url('/api/2116727')  no-repeat  center -160px;}\n\n.full-column-style_2{background: #FAFAFA;}\n.full-column-style_4{background: #FAFAFA;}\n.full-column-style_2 .layout-2-1{min-height: 890px;min-width: 1460px;}/*\n*/ body{min-width: 1400px;}\n","customBackgroundImgButtonList":[]}"
  /*************************** portal **************************** */
  {
    context: ['/portal/'],
    // 原阿里云测试环境
    // target: 'http://magazine-portal.portal-test.founderss.cn',
    // 原阿里云开发环境
    // target: 'http://magazine-portal.dev6.fzyun.io',
    // 现公司测试环境
    // target: 'http://magazine-portal.devx.fzyun.io',
    // 现公司开发环境
    target: 'https://journal.portal.founderss.cn/',
    changeOrigin: true,
    secure: false
  },
  /*************************** resource **************************** */
  {
    context: ['/rc-api/', '/journal-web/'],
    // target: 'http://gateway-web.rc.dev6.fzyun.io',
    target: 'http://gateway-web.publish.testx.fzyun.io',
    changeOrigin: true,
    secure: false
  },
  /*************************** editing **************************** */
  {
    context: ['/je-api/'],
    // 开发环境
    // target: 'http://web.mag-edit.dev6.fzyun.io',
    // 测试环境
    target: 'http://web-mag-edit.testx.fzyun.io',
    changeOrigin: true,
    secure: false
  },
  /*************************** product **************************** */
  {
    context: [
      '/magazine/',
      '/work/',
      '/article/',
      '/book/',
      '/common/',
      '/index/',
      '/userCenter/',
      '/upload/',
      '/importArticle/',
      '/resource/',
      '/comparisonversion/',
      '/newUI/',
      '/huibian/',
      '/tache/'
    ],
    target: 'https://journal.portal.founderss.cn/',
    changeOrigin: true,
    secure: false
  },
  /*************************** Platform Operation **************************** */
  {
    context: [
      '/group',
      '/org/',
      '/magazine/',
      '/magazineMessage/',
      '/magazineMessageOther/'
    ],
    target: 'https://journal.portal.founderss.cn/',
    changeOrigin: true,
    secure: false
  },
  /*************************** 业务中台 **************************** */
  {
    context: ['/baas-api', '/template-key', '/template/'],
    // target: 'https://baas-message.testx.fzyun.io/',
    // target: 'https://journal.testx.fzyun.io/',
    target: 'https://journal.portal.founderss.cn/',
    changeOrigin: true,
    secure: false,
    // 本地开发要加上这个地址，但是千万切记别提交了
    pathRewrite: {
      '^/baas-api': ''
    }
  },
  /*************************** 大数据中心 **************************** */
  {
    context: [
      '/adcs-api/',
      '/adcs-api/api/',
      '/adcs-api/api/article/',
      '/article/',
      '/adcs-api/api/org/',
      '/org/',
      '/api/'
    ],
    target: 'https://journal.portal.founderss.cn/',
    changeOrigin: true,
    secure: false,
    pathRewrite: {
      '^/adcs-api': ''
    }
  }
])

/**
 * 把对象转成数组 - proxy 不支持数组形式
 * 输入：[ { context: [ '/auth', '/api' ], target: 'http://localhost:3000' } ]
 * 输出：{'/auth': { target: 'http://localhost:3000' }, '/api': { target: 'http://localhost:3000' }}
 *
 * @param rules 代理表数组
 * @returns {*}
 */
function transferArrayToObject(rules) {
  return rules.reduce((pre, cur) => {
    if (!Array.isArray(cur.context)) {
      throw new Error(`${JSON.stringify(cur)} 中的 context 不是数组！`)
    }
    // 拷贝一份 proxy 中的 context
    const _context = Array.prototype.slice.call(cur.context)

    // 删除原对象中的 context 属性。转换后的对象不需要context属性，因此在合并前，删掉这个属性
    delete cur.context

    _context.forEach((apiPath) => {
      pre[apiPath] = {
        ...cur
      }
    })
    return pre
  }, {})
}
