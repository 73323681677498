//图片库
export default {
  name: 'imageLibrary',
  // // 列表
  picturesLists: {
    url: '/rc-api/pictures'
  },
  // 发布
  release: {
    url: '/rc-api/common/pubDoc'
  },

  // 高级筛选
  advancedFilters: {
    url: '/rc-api/pictures/advanced/filters'
  },
  uploadimg: {
    url: '/rc-api/pictures/upload'
  },
  // 编辑上传附件
  editImg(imgId) {
    return '/rc-api/pictures/' + imgId + '/update'
  },
  //新增上传图片
  addImg: {
    url: '/rc-api/pictures'
  },
  deleteImg: {
    url: '/rc-api/pictures'
  }, //删除图片信息
  pictureForm: {
    url: '/rc-api/pictures/format'
  }, //下载图片文件得checkbox初始化
  //4.11.图片相关图片
  relevantImg(pictureId) {
    return `/rc-api/pictures/${pictureId}/pictures`
  },
  //4.13.相关文章
  relevantArticles(pictureId) {
    return `/rc-api/pictures/${pictureId}/articles`
  },
  //4.12.图片相关关键词
  relevantKeyWords(pictureId) {
    return `/rc-api/pictures/${pictureId}/keyWords`
  },
  //4.14组内其他图片
  groupImgs(docLibId, docId, destDocLibCode) {
    return `/rc-api/common/groups/${docLibId}/${docId}/${destDocLibCode}`
  },
  //4.15文内其他图片
  // articleImgs(articleId){
  //   return `/rc-api/pictures/articles/${articleId}/pictures`
  // },
  //4.16图片详情
  imgDetail(pictureId) {
    return `/rc-api/pictures/${pictureId}`
  },
  pictureExport: {
    url: '/rc-api/pictures/export'
  }, //导出数据接口
  pictureKeywords: {
    url: '/rc-api/pictures/keyWords'
  },
  pictureskeywords(pictureId) {
    return '/rc-api/pictures/' + pictureId + '/keywords'
  }, //图片标引关键词
  imgDownloadformat: {
    return: '/rc-api/pictures/format'
  }
}
