import APIS from '../../apis'
export default {
  namespaced: true,
  state: {
    urlFetching: false
  },
  actions: {
    async FETCH_CHAT_URL(context, data) {
      const { data: result } = await APIS.portal.getAITalkToken({
        data
      })
      return result
    },
    async OPEN({ dispatch, state }, options) {
      if (state.urlFetching) return
      state.urlFetching = true
      const { data: url } = await dispatch('FETCH_CHAT_URL')

      state.urlFetching = false

      let left = (screen.width - 980) / 2
      let top = (screen.height - 900) / 2
      if (left < 0) left = 0
      if (top < 0) top = 0

      const featureObj = options?.features || {}
      let defaultFeatures = {
        menubar: 'no',
        toolbar: 'no',
        location: 'no',
        status: 'no',
        width: '980',
        height: '900',
        left,
        top,
        ...featureObj
      }
      let features = []
      Object.keys(defaultFeatures).forEach((key) => {
        features.push(`${key}=${defaultFeatures[key]}`)
      })

      window.open(url, '_blank', features.join(','))
    }
  }
}
