// 视频库
export default {
  name: 'videoLibrary',
  //查询视频列表
  videoLise: {
    url: '/rc-api/videoLibs'
  },
  //下载视频附件
  download(videoId) {
    return '/rc-api/videoLibs/' + videoId + '/download'
  },
  // 上传视频
  upload: {
    url: '/rc-api/videoLibs'
  },
  // 编辑视频
  edit(videoId) {
    return `/rc-api/videoLibs/${videoId}`
  },
  // 视频标引关键词
  index: {
    url: '/rc-api/videoLibs/index'
  },
  // 删除视频
  deleteVideo: {
    url: '/rc-api/videoLibs'
  },
  // 视频播放
  // 文章相关视频
  thesesVideos: {
    url: '/rc-api/videoLibs/theses/videos'
  },
  // 相关视频
  relevantVideos: {
    url: '/rc-api/videoLibs/videos'
  },
  // 相关音频
  relevantAudio: {
    url: '/rc-api/videoLibs/voices'
  }
}
