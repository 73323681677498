import APIS from '../../apis'
/**
 * 权限相关
 * 包括登陆、登出等操作
 */
export default {
  namespaced: true,
  state: {
    userName: null,
    authToken:
      'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3UzJFR0l4NjQ0OHBxOWdnVWp3eEpoSmVhYmpmSWNubnRhejR5aXc1U2FnIn0.eyJleHAiOjE2MDk5OTI1NTMsImlhdCI6MTYwNzQwMDU1MywianRpIjoiY2NhMjA2ZGUtZTQ0NS00ODYzLTg1OTMtNTkyMzE2ZTM5MzQwIiwiaXNzIjoiaHR0cDovL3Fpa2FuLmlkcy5zdGFnZS5menl1bi5jbi9hdXRoL3JlYWxtcy9xaWthbiIsImF1ZCI6Im1hZ2F6aW5lLXBvcnRhbCIsInN1YiI6IjIwOTMxMjRiLTEyMjgtNDViYy05MWUzLTdhM2Q4YWU5ZWQ1MSIsInR5cCI6IkJlYXJlciIsImF6cCI6Im1hZ2F6aW5lLXBvcnRhbCIsInNlc3Npb25fc3RhdGUiOiI4NTQyODM3MC1mZjgzLTRlYTEtYjQ4MS01M2I0M2Y5ZGE3ZDEiLCJhY3IiOiIxIiwiYWxsb3dlZC1vcmlnaW5zIjpbImh0dHA6Ly9tYWdhemluZS1wb3J0YWwuZGV2Ni5menl1bi5pbyJdLCJzY29wZSI6ImVtYWlsIHByb2ZpbGUgcGhvbmUiLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsIm5hbWUiOiLpu5jorqTnrqHnkIblkZgiLCJwaG9uZV9udW1iZXIiOiIiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJhZG1pbiIsImZhbWlseV9uYW1lIjoi6buY6K6k566h55CG5ZGYIn0.lJ3vSSxcuDLSlH8TuBR5NqnCZwKB9YsaUvFulb5jdLHLZVxJIQFejG9IZ-U6bmaM_i65-K2E3lp8JMa3PTwb-EdlG46IJDcWR7zF56RFxjH4MKlbkqFs2NUlEEDBmnciC672gMBHrLjV194sCGxq33qAoZJBeRuv8LMljz5vaPm_sv9jRdYW2-qd33MYAnxzB5pstQtOA-dVsx6xkGxEVbhP9GZVe_7QJYw7a-GOC2wW5k6Oxe8qp37JiTfRiEVBGZ0LkJ_a3yH3c-Y8E8_IxKOaY_jjW8pQo21eixngtjulj2dPgqQW-ZddBHYNcq2ih8UjHze3vdXN_IUdntXhIQ',
    expire: 0,
    menuPermission: {
      /*appCenter: []*/
    } // 按照pid存储菜单权限
  },
  getters: {
    IS_LOGGED_IN: (state) => state.authToken !== null,
    AUTH_TOKEN: (state) => state.authToken
  },
  mutations: {
    INIT_AUTHORITY(state) {
      state.userName = null
      state.authToken = null
      state.expire = 0
    },
    UPDATE_AUTHORITY(state, payload) {
      state.userName = payload.userName
      state.authToken = payload.authToken
      state.expire = payload.expire
    },
    LOGOUT() {
      // Cookies.remove(configs.STORAGE_NAMESPACE + 'token')
      this.commit('auth/INIT_AUTHORITY')
      // this.commit('route/ROUTE_CHANGED', { to: { path: RouterTypes.LOGIN } })
    }
  },
  actions: {
    // 按pid获取菜单权限
    async FETCH_MENU_PERMISSION(context, { pid }) {
      const state = context.state
      if (state.menuPermission[pid]) return state.menuPermission[pid]

      const {
        magazineId
      } = await context.dispatch(
        'micro/magazine/GET_MAGAZINE_INFO_WATCH',
        null,
        { root: true }
      )
      let userInfo = await context.dispatch(
        'micro/user/GET_USER_INFO_WATCH',
        null,
        { root: true }
      )

      if (!magazineId || !userInfo.id) return []

      const { data: result } = await APIS.portal.getUserMagazineCenterMenu({
        params: {
          magazineid: magazineId,
          pid,
          userid: userInfo.id
        }
      })
      state.menuPermission[pid] = result.data || []
      return state.menuPermission[pid]
    }
  }
}
