<template>
  <div class="menu-containter">
    <div class="main-menu" :class="{ 'is-collapse': isCollapse }">
      <div class="main-wrapper">
        <div class="main-wrapper-out">
          <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu
              :default-active="defaultActive"
              :collapse="isCollapse"
              :unique-opened="true"
              :collapse-transition="false"
              :router="router"
              mode="vertical"
            >
              <!-- 一级菜单 -->
              <sidebar-item
                v-for="column in menuData"
                :key="column.columnId"
                :item="column"
                :router="router"
                :active-index="activeIndex"
                :sub-active-index="subActiveIndex"
                :is-show-relate-real="isShowRelate"
                :is-handle="isHandle"
                :handle-data="handleData"
                :is-count="isCount"
                @handleActiveIndex="handleActiveIndex"
                @routerChangeUp="routerChangeUp"
              />
            </el-menu>
          </el-scrollbar>

          <slot name="jump" @click="routerJump"></slot>
        </div>

        <div class="coll-btn" :class="isCollapse ? 'img-right' : 'img-left'">
          <img
            :src="collImage"
            :title="isCollapse ? '展开' : '收起'"
            @click="changeCollapse"
          />
        </div>
      </div>
    </div>
    <!-- 显示二级菜单并且二级菜单是打开的状态 -->
    <div
      v-if="SUBMENUSTATE && isSub"
      class="sub-menu"
      :class="{ 'sub-is-collapse': SUBISCOLLAPSE }"
    >
      <SubMenu
        :is-handle="isHandle"
        :is-sub-menu="menuData[0].childrenList[0].isSubMenu"
        :handle-data="handleData"
        :sub-menu-data="menuData[0].childrenList[0].subMenuData"
        :active-index-relate="activeIndexRelate"
        :sub-active-index-relate="subActiveIndexRelate"
        @handleSubMenu="handleSubMenu"
        @checkValueChange="checkValueChange"
        @subMenuClick="subMenuClick"
        @handleOpreate="handleOpreate"
      ></SubMenu>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SidebarItem from './SidebarItem'
import SubMenu from './SubMenu'
export default {
  components: { SidebarItem, SubMenu },
  props: {
    defaultActive: {
      type: String,
      default: ''
    },
    menuData: {
      type: Array,
      default: () => []
    },
    router: {
      type: Boolean,
      default: false
    },
    isHandle: {
      type: Boolean,
      default: false
    },
    // 是否显示二级菜单
    isSub: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/require-default-prop
    handleData: {
      type: Array,
      dafault: () => []
    },
    isCount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: '0',
      subActiveIndex: '',
      defaultOpeneds: [],
      isCollapse: false,
      isShowRelate: false,
      collImageLeft: require('@main/src/assets/img/sidebar/icon-left-arrow.png'),
      collImageRight: require('@main/src/assets/img/sidebar/icon-right-arrow.png'),
      //关联菜单相关
      activeIndexRelate: '0',
      subActiveIndexRelate: '',
      subIsCollapse: false,
      isSubMenu: true,
      subMenuData: []
    }
  },
  computed: {
    ...mapState('micro/sidebar', {
      SUBISCOLLAPSE: 'subIsCollapse',
      // 二级菜单打开状态
      SUBMENUSTATE: 'subMenuState',
      ISCOLLAPSE: 'isCollapse'
    }),
    collImage() {
      if (this.isCollapse) {
        return this.collImageRight
      } else {
        return this.collImageLeft
      }
    }
  },
  watch: {
    defaultActive(n) {
      if (n) {
        let defaultActive = this.defaultActive.split('-')
        this.activeIndex = defaultActive[0]
        this.subActiveIndex = this.defaultActive
        // if (defaultActive.length == 2) {
        //   this.defaultOpeneds = [defaultActive[0]]
        // } else {
        //   this.defaultOpeneds = [defaultActive[0] + '_' + defaultActive[1]]
        // }
      }
    }
  },
  mounted() {
    let defaultActive = this.defaultActive.split('-')
    this.activeIndex = defaultActive[0]
    this.subActiveIndex = this.defaultActive
    this.$store.commit('micro/sidebar/UPDATE_STATE', {
      name: 'isCollapse',
      data: false
    })
  },
  methods: {
    changeCollapse() {
      this.isCollapse = !this.isCollapse
      this.$store.commit('micro/sidebar/UPDATE_STATE', {
        name: 'isCollapse',
        data: this.isCollapse
      })
    },
    handleActiveIndex(value) {
      this.activeIndex = value.activeIndex
      this.subActiveIndex = value.subActiveIndex

      // 离开时关联菜单不存在
      this.$store.commit('micro/sidebar/UPDATE_STATE', {
        name: 'subMenuState',
        data: value.isSubMenu
      })
    },
    handleOpreate(value) {
      this.$emit('upHandleOpreate', value)
    },
    routerChangeUp(value) {
      if (value.isSubMenu) {
        // 切换菜单是重制关联菜单状态
        this.$store.commit('micro/sidebar/UPDATE_STATE', {
          name: 'subIsCollapse',
          data: false
        })
      }
      if (value.path == '/channelManage') {
        this.$store.commit('micro/sidebar/UPDATE_STATE', {
          name: 'subMenuState',
          data: true
        })
      }

      this.$emit('routerChange', value)
    },

    // 关联菜单相关
    handleSubMenu(value) {
      sessionStorage.removeItem('checkValue')
      this.$emit('handleCurrentMenu', value)
    },
    checkValueChange(value) {
      this.$emit('handleCheckValue', value)
    },
    subMenuClick(value) {
      this.$emit('routerChildChange', value)
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/css/var.scss';
.menu-containter {
  height: 100%;
  white-space: nowrap;
  .main-menu {
    display: inline-block;
    vertical-align: top;
    width: 200px;
    height: 100%;

    &.is-collapse {
      width: 60px;

      .jump {
        display: none;
      }
    }
  }
  .sub-menu {
    display: inline-block;
    vertical-align: top;
    width: 170px;
    height: 100%;
    overflow-y: hidden;
    // margin-left: 6px;
    &.sub-is-collapse {
      width: 50px;
    }
  }

  .el-menu-item,
  .el-submenu__title {
    color: #404040;
    height: 30px;
    line-height: 30px;
  }
  .el-menu {
    padding: 15px 0 0;
    margin-bottom: 15px;
  }
  .el-submenu {
    .el-menu {
      padding: 0px;
      margin-bottom: 0;
    }
  }
}
.main-wrapper {
  position: relative;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
  .el-menu--collapse {
    width: 60px;
    .cus-sidebar-item {
      .el-submenu {
        .el-submenu__title {
          .menu-icon-arrow {
            display: none;
          }
        }

        &.is-active {
          .el-submenu__title {
            border-right: 2px solid #4054af;
            background: #d8ddf6;
          }
        }
      }
    }

    .el-submenu__title {
      .menu-icon-arrow {
        display: none;
      }
    }
  }
  .el-scrollbar {
    height: auto;
  }
  .main-wrapper-out {
    height: calc(100vh - 200px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  .scrollbar-wrapper {
    overflow-x: hidden;
    margin-bottom: 0 im !important;
  }
}

.coll-btn {
  width: 96%;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #fff;
  padding: 20px 0 20px;
  text-align: center;
  z-index: 1;
  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    line-height: 50px;
    background-color: #f0f2f5;
    position: absolute;
    bottom: 45px;
    z-index: -1;
  }
  &.img-left {
    img {
      width: 46px;
      height: 46px;
    }
  }
  &.img-right {
    img {
      width: 38px;
      height: 38px;
      position: relative;
      top: -5px;
    }
  }
  img {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
<style scoped lang="scss">
@import '../../assets/css/var.scss';
.jump {
  width: 152px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7c8cca;
  color: #fff;
  height: 28px;
  line-height: 28px;
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
  span {
    display: inline-block;
    padding-right: 5px;
  }
  img {
    width: 16px;
    height: 12px;
    display: inline-block;
  }
  &:hover {
    background-color: #4054af;
  }
}
</style>
