//基金库
export default {
  name: 'fundList',
  fundDetailChart(fundId) {
    return '/rc-api/funds/' + fundId + '/detail'
  },
  list: {
    url: '/rc-api/funds'
  },
  template: {
    url: '/rc-api/funds/template'
  },
  repetition: {
    url: '/rc-api/funds/repetition'
  },
  //基金修改
  fundsModify(docId) {
    return `/rc-api/funds/${docId}`
  },
  fundsImport: {
    url: '/rc-api/funds/import'
  },
  // 刊群导入
  fundsGroupImport: {
    url: '/rc-api/funds/importGroupData'
  },
  //删除基金
  fundsDelete: {
    url: `/rc-api/funds`
  },
  fundsExport: {
    url: '/rc-api/funds/export'
  },
  foundKeyDisciplines(fundId) {
    return '/rc-api/funds/' + fundId + '/keyDisciplines'
  },
  articlesList(fundId) {
    return '/rc-api/funds/' + fundId + '/articles'
  },
  organizations(fundId) {
    return '/rc-api/funds/' + fundId + '/organizations'
  },
  // 获基金支持作者
  fundAuthors(fundId) {
    return '/rc-api/funds/' + fundId + '/authors'
  },
  relatedFunds(fundId) {
    return '/rc-api/funds/' + fundId + '/funds'
  },
  // 新建基金
  addFund: {
    url: '/rc-api/funds/addFund'
  },
  merge(docLibId) {
    return `/rc-api/common/${docLibId}/repetition/merge`
  }
}
