export default {
  name: 'authorLibrary',
  // 作者列表
  authorList: {
    url: '/rc-api/authorLibs'
  },
  // 作者编辑
  authorEdit(docId) {
    return `/rc-api/authorLibs/${docId}/update`
  },
  authorLibsResume(docId) {
    return `/rc-api/authorLibs/resume/${docId}`
  },
  authorLibsPeer(docId) {
    return `/rc-api/authorLibs/peer/${docId}`
  },
  // 作者头像修改
  // picEdit(docId){
  //     return `/rc-api/authorLibs/${docId}/pic`
  // },
  // 作者详情
  authorDetail(authorId) {
    return `/rc-api/authors/detail/${authorId}`
  },
  // 发文量
  thesesNum(docId) {
    return `/rc-api/authorLibs/${docId}/thesesNum`
  },
  published(authorId) {
    return `/rc-api/authors/published/${authorId}`
  },
  beQuated(authorId) {
    return `/rc-api/authors/beQuated/${authorId}`
  },
  beDownload(authorId) {
    return `/rc-api/authors/beDownload/${authorId}`
  },
  quate(authorId) {
    return `/rc-api/authors/quate/${authorId}`
  },
  // 作者查重
  getRepeat: {
    url: '/rc-api/authorLibs/repetition'
  },
  // 合并
  merge: {
    url: '/rc-api/authorLibs/repetition/merge'
  },
  // 合作作者
  partnerAuthor: {
    url: '/rc-api/authorLibs/partnerAuthor'
  },
  // 合作机构
  partnerOrg: {
    url: '/rc-api/authorLibs/partnerOrg'
  },
  template: {
    url: '/rc-api/authorLibs/template'
  },
  import: {
    url: '/rc-api/authorLibs/import'
  },
  // 刊群导入
  importGroup: {
    url: '/rc-api/authorLibs/importGroupData'
  },
  // 新建作者
  addAuthor: {
    url: '/rc-api/authorLibs/addAuthor'
  },
  // 合著作者
  coAuthorWork: {
    url: '/rc-api/authorLibs/coAuthorWork'
  },
  // 设置专家作者
  setProfessor: {
    url: '/rc-api/authorLibs/setProfessor'
  }
}
