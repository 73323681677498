export default {
  name: 'journalInfo',
  get: {
    url: '/magazine/findMagazineById'
  },
  save: {
    url: '/magazine/update'
  },
  orgSave: {
    url: '/magazine/magazineInfo/orgUpdate.do'
  },
  enable: {
    url: '/magazine/magazineEnable.do'
  },
  titleCheck: {
    url: 'magazine/checkparam'
  },
  getUserList: {
    url: '/magazine/magazineInfo/getFormData.do'
  },
  numberCheck: {
    url: '/magazine/numberCheck.do' //刊物 CN号重复校验（武岳明）
  },
  getJournalInfo: {
    url: '/magazine/findMagazineById'
  }
}
