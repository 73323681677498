//表格库
export default {
  name: 'tableLibrary',
  // // 列表
  picturesLists: {
    url: '/rc-api/tables'
  },
  uploadimg: {
    url: '/rc-api/tables/upload'
  },
  // 发布
  release: {
    url: '/rc-api/common/pubDoc'
  },
  // 编辑上传附件
  editImg(imgId) {
    return '/rc-api/tables/' + imgId + '/update'
  },
  // 高级筛选
  advancedFilters: {
    url: '/rc-api/tables/advanced/filters'
  },
  //新增上传图片
  addImg: {
    url: '/pictures'
  },
  deleteImg: {
    url: '/rc-api/tableLibs'
  }, //删除图片信息
  pictureForm: {
    url: '/pictures/format'
  }, //下载图片文件得checkbox初始化
  //4.11.图片相关图片
  relevantImg(pictureId) {
    return `/pictures/${pictureId}/pictures`
  },
  //4.13.相关文章
  relevantArticles(pictureId) {
    return `/pictures/${pictureId}/articles`
  },
  //4.12.图片相关关键词
  relevantKeyWords(pictureId) {
    return `/pictures/${pictureId}/keyWords`
  },
  //4.14文内其他表格
  groupImgs(docLibId, docId, destDocLibCode) {
    return `/rc-api/common/groups/${docLibId}/${docId}/${destDocLibCode}`
  },
  //4.16图片详情
  imgDetail(pictureId) {
    return `/pictures/${pictureId}`
  },
  pictureExport: {
    url: '/pictures/export'
  }, //导出数据接口
  pictureKeywords: {
    url: '/pictures/keyWords'
  }
}
